var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-sm-12 col-md-6 col-lg-4 assignment-container" },
    [
      _c("div", { staticClass: "assignment-inner" }, [
        _c("div", {
          staticClass: "top",
          style: { backgroundImage: "url(" + _vm.assignment.avatarUrl + ")" },
        }),
        _c("div", { staticClass: "bottom" }, [
          _c("p", { staticClass: "font-weight-bold mt-2 mb-0" }, [
            _vm._v(" " + _vm._s(_vm.assignment.name)),
          ]),
          _c("p", { staticClass: "role-name" }, [
            _vm._v(" " + _vm._s(_vm.assignment.role.name)),
          ]),
          _c("p", { staticClass: "d-flex icons text-primary" }, [
            _vm.assignment.emailAddress
              ? _c(
                  "a",
                  { attrs: { href: "mailto:" + _vm.assignment.emailAddress } },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "fas fa-envelope mr-3 fa-md",
                      attrs: { title: _vm.assignment.emailAddress },
                    }),
                  ]
                )
              : _vm._e(),
            !_vm.assignment.emailAddress && _vm.assignment.role.email
              ? _c(
                  "a",
                  { attrs: { href: "mailto:" + _vm.assignment.role.email } },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "fas fa-envelope mr-3 fa-md",
                      attrs: { title: _vm.assignment.role.email },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.assignment.mobileNumber
              ? _c("a", [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "fas fa-mobile-alt mr-3 fa-md",
                    attrs: { title: _vm.assignment.mobileNumber },
                  }),
                ])
              : _vm._e(),
            _vm.assignment.phoneNumber
              ? _c("a", [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "fas fa-phone-rotary mr-3 fa-md",
                    attrs: { title: _vm.assignment.phoneNumber },
                  }),
                ])
              : _vm._e(),
            _vm.assignment.role.description
              ? _c("a", [
                  _c("i", {
                    staticClass: "fas fa-info mr-3 fa-md",
                    on: {
                      click: function ($event) {
                        return _vm.showDescriptionModal(_vm.assignment.role)
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }