




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrganizationRoleAssignment } from '../../models/organization-role-assignment.model';
import { OrganizationRole } from '../../models/organization-role.model';

@Component
export default class OrganizationAssignmentTile extends Vue {

  @Prop()
  assignment!: OrganizationRoleAssignment;
  @Prop()
  isFirst!: boolean;
  @Prop()
  isLast!: boolean;

  public showDescriptionModal(role: OrganizationRole): void {
    this.$emit('selectedRole', role);
  }
}

