






















































import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { OrganizationCategory } from '../models/organization-category.model';
import { OrganizationRoleAssignment } from '../models/organization-role-assignment.model';
import { OrganizationRole } from '../models/organization-role.model';
import { Organization as OrganizationModel } from '../models/organization.model';
import OrganizationAssignmentTile from './components/OrganizationAssignmentTile.vue';

@Component({
  components: {
    'assignment-tile': OrganizationAssignmentTile
  }
})
export default class Organization extends Vue {
  @Action
  private loadOrganizationData!: () => Promise<void>;
  @Action
  private setLoading!: (loading: boolean) => Promise<void>;

  @Getter
  private organization!: OrganizationModel | null;

  selectedRoleForDescription: OrganizationRole | null = null;
  showDescriptionModal = false;

  async created(): Promise<void> {
    await this.setLoading(true);
    try {
      await this.loadOrganizationData();
    } catch {
      ToastHelper.showError(this.$bvToast, this.$t('shared.errorMessage'))
    } finally {
      await this.setLoading(false);
    }
  }

  getAssignmentsRows(category: OrganizationCategory): OrganizationRoleAssignment[][] {
    const assignments = [...category.assignments];
    const result: OrganizationRoleAssignment[][] = [];
    const perRow = 3;
    while (assignments.length > 0) {
      result.push(assignments.splice(0, perRow))
    }
    return result;
  }

  onSelectedRole(role: OrganizationRole): void {
    this.selectedRoleForDescription = role;
    this.showDescriptionModal = true;
  }
}
