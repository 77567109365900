var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("h5", { staticClass: "col-12 mt-3 mb-3" }, [_vm._v("Organisatie")]),
      _vm.organization
        ? _c(
            "div",
            { staticClass: "col-12" },
            _vm._l(_vm.organization.categories, function (category) {
              return _c(
                "div",
                { key: category.id, staticClass: "category row col-12" },
                [
                  _vm._l(category.subCategories, function (subCategory) {
                    return _c(
                      "div",
                      {
                        key: subCategory.id,
                        staticClass: "sub-category row col-12",
                      },
                      [
                        subCategory.assignments.length > 0
                          ? _c(
                              "div",
                              { staticClass: "assignments col-12 row" },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "col-12 pl-0 mt-3 text-primary category-name",
                                  },
                                  [
                                    _vm._v(" " + _vm._s(category.name) + " "),
                                    category.email
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "mail-icon",
                                            attrs: {
                                              href: "mailto:" + category.email,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass:
                                                "icon-hover fas fa-envelope mr-3 fa-md",
                                              attrs: { title: category.email },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "h6",
                                  {
                                    staticClass: "col-12 pl-0 subcategory-name",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(subCategory.name) + " "
                                    ),
                                    subCategory.email
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "mail-icon",
                                            attrs: {
                                              href:
                                                "mailto:" + subCategory.email,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass:
                                                "icon-hover fas fa-envelope mr-3 fa-md",
                                              attrs: {
                                                title: subCategory.email,
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._l(
                                  _vm.getAssignmentsRows(subCategory),
                                  function (subAssignmentRow, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "assignment-row col-12 row",
                                      },
                                      _vm._l(
                                        subAssignmentRow,
                                        function (assignment, index) {
                                          return _c("assignment-tile", {
                                            key: index,
                                            attrs: { assignment: assignment },
                                            on: {
                                              selectedRole: _vm.onSelectedRole,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  category.assignments.length > 0
                    ? _c(
                        "div",
                        { staticClass: "assignments col-12 row" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "col-12 pl-0 mt-3 text-primary category-name",
                            },
                            [
                              _vm._v(" " + _vm._s(category.name) + " "),
                              category.email
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "mail-icon",
                                      attrs: {
                                        href: "mailto:" + category.email,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        staticClass:
                                          "icon-hover fas fa-envelope mr-3 fa-md",
                                        attrs: { title: category.email },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._l(
                            _vm.getAssignmentsRows(category),
                            function (assignmentRow, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "assignment-row col-12 row",
                                },
                                _vm._l(
                                  assignmentRow,
                                  function (assignment, index) {
                                    return _c("assignment-tile", {
                                      key: index,
                                      attrs: { assignment: assignment },
                                      on: { selectedRole: _vm.onSelectedRole },
                                    })
                                  }
                                ),
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm.selectedRoleForDescription
        ? _c(
            "b-modal",
            {
              attrs: {
                title: _vm.selectedRoleForDescription.name,
                "ok-only": true,
                "ok-title": _vm.$t("shared.close"),
                "ok-variant": "secondary",
              },
              model: {
                value: _vm.showDescriptionModal,
                callback: function ($$v) {
                  _vm.showDescriptionModal = $$v
                },
                expression: "showDescriptionModal",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.selectedRoleForDescription.description) + " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }